import { Button, Flex, FlexProps, Heading, Link, Text } from "@chakra-ui/react";
import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import theme from "../../@chakra-ui/gatsby-plugin/theme";

export const SubmissionSection = (props: FlexProps) => {
  return (
    <Flex as="section" flexDirection="column" {...props}>
      <Flex
        flexDirection="column"
        border="1px"
        borderColor="blackAlpha.200"
        borderRadius="lg"
        shadow="lg"
        padding="8"
      >
        <Heading color="delta" fontSize="2xl" fontWeight="semibold" as="h2">
          How to share?
        </Heading>
        <Flex flexDirection="column" mt="4">
          <Text color="blackAlpha.700" mt={[2, null, null, 0]}>
            You can also share your ideas and resources to this site. To do so,
            you click on the submit form button or you can send an email to{" "}
            <Link
              textDecoration="underline"
              href="mailto:centenary-resources@bahai.org.my"
            >
              centenary-resources@bahai.org.my
            </Link>
          </Text>
          <Text fontStyle="italic" fontSize="sm" color="blackAlpha.700" my="2">
            Note: You may not be able to instantly view your uploaded file until
            it is filtered and categorised accordingly by the team managing the
            repository.
          </Text>
          <Link
            mt="4"
            as={GatsbyLink}
            width="100%"
            to={"/share"}
            justifyContent="center"
            display="flex"
            _hover={{
              textDecoration: "none",
            }}
            isExternal
          >
            <Button
              backgroundColor="brand"
              width={["100%", null, null, "200px"]}
              color="white"
              _hover={{
                backgroundColor: theme.colors.zorba,
              }}
              _active={{
                backgroundColor: theme.colors.sage,
              }}
            >
              Submit Form
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};
