import { Button, Flex } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import * as React from "react";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";
import { Main } from "../components/Main";
import { HeaderBar } from "../components/HeaderBar";
import { HeroSection } from "../features/home/HeroSection";
import { IntroductionSection } from "../features/home/IntroductionSection";
import { SubmissionSection } from "../features/home/SubmissionSection";

const IndexPage = () => {
  return (
    <Layout>
      <Header>
        <HeaderBar />
      </Header>
      <Main pb="16" alignItems="center">
        <HeroSection />
        <Flex
          flexDirection="column"
          width="100%"
          maxWidth="120ch"
          alignItems="center"
        >
          <Button
            as={GatsbyLink}
            to="/resources?category=ALL"
            display={["none", null, null, "flex"]}
            mt="12"
            size="lg"
            color="white"
            backgroundColor="sage"
            textTransform="uppercase"
            _hover={{
              backgroundColor: "brand",
            }}
            _active={{
              backgroundColor: "brand",
            }}
          >
            Click to View Resources
          </Button>
          <IntroductionSection
            marginTop="8"
            paddingBottom="0"
            flex={[null, null, null, 1]}
          />
          <Flex
            flexDirection={["column", null, null, "row"]}
            alignItems="flex-start"
            marginBottom="8"
          >
            <SubmissionSection flex={1} paddingY="4" paddingX="8" />
          </Flex>
        </Flex>
      </Main>
    </Layout>
  );
};

export default IndexPage;
