import { Flex, FlexProps, Heading } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react";
import theme from "../../@chakra-ui/gatsby-plugin/theme";
import { MarkdownContainer } from "../../components/MarkdownContainer";

export const IntroductionSection = (props: FlexProps) => {
  const data = useStaticQuery(graphql`
    query homeSection {
      mdx(fileAbsolutePath: { regex: "/content/sections/home/" }) {
        frontmatter {
          title
        }
        body
      }
    }
  `);

  return (
    <Flex as="section" padding="8" flexDirection="column" {...props}>
      <Heading color="delta" fontSize="2xl" fontWeight="semibold" as="h2">
        {data.mdx.frontmatter.title}
      </Heading>
      <SectionContainer marginY="4">
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </SectionContainer>
    </Flex>
  );
};

const SectionContainer = styled(MarkdownContainer)`
  padding: 0;
  color: ${theme.colors.gray[800]};
  p {
    padding: 0;
  }
`;
