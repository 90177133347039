import { Button, Flex, Heading, theme } from "@chakra-ui/react";
import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import styled from "@emotion/styled";
import { Link as GatsbyLink } from "gatsby";

export const HeroSection = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "hero-image.jpeg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);
  return (
    <Flex as="section" minHeight={["lg", null, null, "xs"]} minWidth="100%">
      <Container {...bgImage}>
        <Flex
          position="absolute"
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          maxWidth={["85%", null, null, "50ch"]}
          bottom={[2, null, null, 4]}
          padding={[4, null, null, 2]}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            width="100%"
            borderRadius={[null, null, null, "md"]}
            padding={[null, null, null, 2]}
            backgroundColor={[null, null, null, "blackAlpha.700"]}
            opacity={0.9}
          >
            <Heading
              as="h1"
              color="white"
              textTransform="uppercase"
              fontSize="2xl"
              fontWeight="normal"
              textShadow={[
                `2px 2px 8px ${theme.colors.blackAlpha[700]}`,
                null,
                null,
                "none",
              ]}
            >
              Centenary of the Ascension of Abdu'l Baha
            </Heading>
          </Flex>
          <Button
            as={GatsbyLink}
            display={["flex", null, null, "none"]}
            to="/resources?category=ALL"
            mt="4"
            color="white"
            size="lg"
            backgroundColor="sage"
            textTransform="uppercase"
            shadow="lg"
          >
            View Resources
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
};

const Container = styled(BackgroundImage)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${theme.space[4]};
  position: relative;
`;
